import React, { useState } from "react";
import SidePage from "../../components/get-a-quotation-form";
import EmailForm from "./form/product-yarn-email-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function EcosEvolve() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <div style={{ marginTop: "50px" }}>
        <div className="ecose-page-gap">
          <div className="two-column-para">
            <div className="column-para column1-para">
              {/* Column 1 */}
              <img
                className="ecose-page-image"
                alt=""
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/EVOLE.jpg"
              />
            </div>
            <div className="column-para column2-para">
              {/* Column 2 */}
              <img
                className="ecose-page-image-2"
                alt=""
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/evolve-e1612100001509.png"
              />
              <h2 className="ecos-page-topic">
                BRIGHT COLOURS KEEP IT NATURAL
              </h2>
              <p className="ecos-page-sub-topic-2">
                Yarn Created With Natural Colours. Creativity Meets Quality As
                We Continue To Push Boundaries Inventing New Ideas And
                Approaches.
              </p>
              <p className="ecos-page-para">
                To leave the environment cleaner than we found it, we must
                measure the impact of our products. We take pride in being the
                first yarn manufacturer in the world to transform flakes to POY
                on a commercial basis. Unlike ordinary recycled polyester, our
                process eliminates the need for polymer chips. This conscious
                action ensures the PET we recycle emits the lowest carbon foot
                print in the world.
              </p>
              <h2 className="ecos-page-topic">Potential Benefits:</h2>
              <p className="ecos-page-para">
                {/* <p className="ecos-page-sub-para">
                  Energy and water consumption usage at lowest levels
                </p>
                <p className="ecos-page-sub-para">Highest color fastness</p>
                <p className="ecos-page-sub-para">
                  Due to unique features no pigments and dyestuff used
                </p>
                <p className="ecos-page-sub-para">
                  Natural color of the bottles evident in the yarn
                </p> */}
                <table style={{ width: "100%", textAlign: "start" }}>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Energy and water consumption usage at lowest levels
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Highest color fastness
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Due to unique features no pigments and dyestuff used
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Natural color of the bottles evident in the yarn
                      </p>
                    </td>
                  </tr>
                </table>
              </p>
              <div className="horizontal-container">
                <div className="topic-container">
                  <div>
                    <p className="horizontal-line-ecos-text">END USES:</p>
                  </div>
                  <div className="horizontal-line-ecos right"></div>
                </div>
                <img
                  className="ecose-page-image-p"
                  alt=""
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/Evolve.png"
                />
              </div>
              <br />
              <div>
                <div className="product-yarn-button-container">
                  <a
                    onClick={toggleSidebar}
                    className="product-yarn-button-column"
                  >
                    <p className="product-yarn-button-text">
                      <center>GET A QUOTATION</center>
                    </p>
                  </a>

                  <a
                    href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/Ecos-Evolve.pdf"
                    className="product-yarn-button-column"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="product-yarn-button-text">
                      <center>DOWNLOAD BROUCHURE</center>
                    </p>
                  </a>
                </div>
              </div>

              <div style={{ marginLeft: "10px" }}>
                <EmailForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSidebarOpen && <SidePage closeSidebar={toggleSidebar} />}
    </div>
  );
}
