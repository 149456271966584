import React from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import YarnNavbar from "./yarn-navbar";
import Footer from "../../components/footer";

export default function productYarn() {
  return (
    <div>
      <NavBar />

      <div style={{ marginBottom: "-3px" }}>
        <div style={{ position: "relative" }}>
          <img
            className="home-image-2"
            alt=""
            src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/01.jpg"
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "80vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 0,
            }}
          ></div>
          <div className="video-text-box">
            <div className="video-background-text">
              <h1 className="video-topic">
                TURNING THE TIDE WITH
                <br />
                PLASTIC WASTE
              </h1>
              <p className="video-para">
                TRANSFORMING WASTE INTO
                <br />
                INNOVATIVE TEXTILES
              </p>
            </div>
          </div>
          <div className="product-yarn-box">
            <img
              alt=""
              src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/Untitled-2-01.png"
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>

      <YarnNavbar />

      <Footer />
    </div>
  );
}
