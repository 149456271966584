import React, { useState } from "react";
import SidePage from "../../components/get-a-quotation-form";
import EmailForm from "./form/product-yarn-email-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function EcosChroma() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <div style={{ marginTop: "50px" }}>
        <div className="ecose-page-gap">
          <div className="two-column-para">
            <div className="column-para column1-para">
              {/* Column 1 */}
              <img
                className="ecose-page-image"
                alt=""
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/chroma.jpg"
              />
            </div>
            <div className="column-para column2-para">
              {/* Column 2 */}
              <img
                className="ecose-page-image-2"
                alt=""
                src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/chroma-e1612100091651.png"
              />
              <h2 className="ecos-page-topic">
                AS ENVIRONMENTAL ISSUES INCREASE, PRODUCING COLOURED YARN
                INNOVATIVELY REDUCES ENERGY CONSUMPTION AND WATER POLLUTION.
              </h2>
              <p className="ecos-page-para">
                Conventional bulk dyeing is highly water intensive. By using
                recycled dope-dying, coloured yarn is produced without using
                water, which helps to reduce overall water consumption by more
                than 50%. Compared to traditional fabric dying or package
                dyeing, this saves tremendous amounts of water.
              </p>
              <h2 className="ecos-page-topic">Potential Benefits:</h2>
              <p className="ecos-page-para">
                {/* <p className="ecos-page-sub-para">
                  Reducing water and energy consumption by eliminating the
                  dyeing process
                </p>
                <p className="ecos-page-sub-para">
                  Deeper color penetration provides high color fastness
                </p>
                <p className="ecos-page-sub-para">
                  Better flexibility in obtaining custom color matching
                </p>
                <p className="ecos-page-sub-para">
                  Ability to reduce the minimum order quantity
                </p>
                <p className="ecos-page-sub-para">
                  End product cost is lower compared to conventional fabric
                  dyeing
                </p>
                <p className="ecos-page-sub-para">
                  Capable of achiving colour uniformity
                </p> */}
                <table style={{ width: "100%", textAlign: "start" }}>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Reducing water and energy consumption by eliminating the
                        dyeing process
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Deeper color penetration provides high color fastness
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Better flexibility in obtaining custom color matching
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Ability to reduce the minimum order quantity
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        End product cost is lower compared to conventional
                        fabric dyeing
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "25px", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para-arrow">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </p>
                    </td>
                    <td style={{ width: "auto", verticalAlign: "top" }}>
                      <p className="ecos-page-sub-para">
                        Capable of achiving colour uniformity
                      </p>
                    </td>
                  </tr>
                </table>
              </p>
              <div className="horizontal-container">
                <div className="topic-container">
                  <div>
                    <p className="horizontal-line-ecos-text">END USES:</p>
                  </div>
                  <div className="horizontal-line-ecos right"></div>
                </div>
                <img
                  className="ecose-page-image-p"
                  alt=""
                  src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/product-yarn/Chorma.png"
                />
              </div>
              <br />
              <div>
                <div className="product-yarn-button-container">
                  <a
                    onClick={toggleSidebar}
                    className="product-yarn-button-column"
                  >
                    <p className="product-yarn-button-text">
                      <center>GET A QUOTATION</center>
                    </p>
                  </a>

                  <a
                    href="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pdf/Ecos-Chorma.pdf"
                    className="product-yarn-button-column"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="product-yarn-button-text">
                      <center>DOWNLOAD BROUCHURE</center>
                    </p>
                  </a>
                </div>
              </div>

              <div style={{ marginLeft: "10px" }}>
                <EmailForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSidebarOpen && <SidePage closeSidebar={toggleSidebar} />}
    </div>
  );
}
