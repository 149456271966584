import React, { useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import "../../../styling/templates.css";

function App() {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState("");

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
    setCaptchaError(""); // Clear any previous captcha error
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!captchaToken) {
      setCaptchaError("Please complete the hCaptcha verification.");
      return;
    }

    const formData = new FormData(event.target);

    // Append static fields
    formData.append(
      "subject",
      formData.get("subject") || "ECO Spindles - Get a Quotation Form"
    );
    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");
    formData.append("h-captcha-response", captchaToken);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const responseData = await res.json();

      if (res.ok && responseData.success) {
        setSuccessMessage("Form submitted successfully!");
        setErrorMessage("");
        window.location.href = formData.get("redirect");
      } else {
        setErrorMessage(
          responseData.message || "Form submission failed. Please try again."
        );
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage("An unexpected error occurred. Please try again later.");
      setSuccessMessage("");
    }
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setShowOtherInput(selectedValue === "Other");
  };

  return (
    <div style={{ padding: "50px", paddingTop: "0px" }}>
      <form onSubmit={onSubmit} id="ecospindlesgetaquotation">
        <div className="form-three-column-paragraph">
          <div className="form-column">
            <p className="topic-form">Company Name</p>
            <input
              type="text"
              name="Company Name:"
              required
              className="inputStyle"
            />
          </div>
          <div className="form-column">
            <p className="topic-form">Nature of Business</p>
            <input
              type="text"
              name="Nature of Business:"
              required
              className="inputStyle"
            />
          </div>
          <div className="form-column">
            <p className="topic-form">Company Email</p>
            <input
              type="email"
              name="Company Email:"
              required
              className="inputStyle"
            />
          </div>
        </div>

        <div className="second-three-column-paragraph">
          <div className="second-column">
            <p className="topic-form">Interested Product</p>
            <select
              name="Interested Product:"
              required
              className="dropDownStyle"
              onChange={handleDropdownChange}
            >
              <option value="Yarn">Yarn</option>
              <option value="Monofilament">Monofilament</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {showOtherInput && (
            <div className="second-column">
              <p className="topic-form">Specify Other Product</p>
              <input
                type="text"
                name="Other Product:"
                required
                className="inputStyle"
              />
            </div>
          )}

          <div className="second-column">
            <p className="topic-form">Product Description</p>
            <textarea
              name="Product Description:"
              rows="5"
              required
              className="textareaStyle"
            ></textarea>
          </div>
          <div className="second-column">
            <p className="topic-form">How did you get to know about us?</p>
            <br />

            <div className="one-line">
              <div className="radio-group">
                <input
                  type="radio"
                  id="website"
                  name="How did you get to know about us?"
                  value="Website"
                  required
                />
                <label htmlFor="website" className="from-page-radio">
                  &nbsp; Website &nbsp;&nbsp;
                </label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  id="fb"
                  name="How did you get to know about us?"
                  value="FB"
                  required
                />
                <label htmlFor="fb" className="from-page-radio">
                  &nbsp; FB &nbsp;&nbsp;
                </label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  id="linkedin"
                  name="How did you get to know about us?"
                  value="LinkedIn"
                  required
                />
                <label htmlFor="linkedin" className="from-page-radio">
                  &nbsp; LinkedIn &nbsp;&nbsp;
                </label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  id="youtube"
                  name="How did you get to know about us?"
                  value="YouTube"
                  required
                />
                <label htmlFor="youtube" className="from-page-radio">
                  &nbsp; YouTube &nbsp;&nbsp;
                </label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  id="others"
                  name="How did you get to know about us?"
                  value="Others"
                  required
                />
                <label htmlFor="others" className="from-page-radio">
                  &nbsp; Others &nbsp;&nbsp;
                </label>
              </div>
            </div>
          </div>
        </div>

        <br />
        <input
          type="hidden"
          name="ccemail"
          value="shenali@3cs.solutions; priyankara@3cs.solutions"
        />
        <input
          type="hidden"
          name="redirect"
          value="https://web3forms.com/success"
        />

        <input type="checkbox" name="botcheck" style={{ display: "none" }} />
        <input name="from_name" type="hidden" value="ECO Spindles" />
        <input
          name="subject"
          type="hidden"
          value="ECO Spindles - Get a Quotation Form"
        />

        {/* hCaptcha widget */}
        <div>
          <HCaptcha
            sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
            reCaptchaCompat={false}
            onVerify={handleCaptchaVerify}
          />
        </div>

        <div className="form-footer-top">
          <button
            type="submit"
            className="btn second-submitButton btn-lg"
            id="button"
          >
            Submit
          </button>
          <br />
          <br />
          <div style={{ height: "30px", paddingTop: "0.5px" }}>
            {captchaError || errorMessage ? (
              <div className="error-message-2">
                {captchaError || errorMessage}
              </div>
            ) : successMessage ? (
              <div className="success-message-2">{successMessage}</div>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
}

export default App;
